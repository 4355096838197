@tailwind base;
@tailwind components;
@tailwind utilities;


.shadow-def {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


.rubik-font {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 20px;
  font-style: normal;
}

.kanit-thin {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.kanit-extralight {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.kanit-light {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kanit-semibold {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.kanit-bold {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kanit-extrabold {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.kanit-black {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.kanit-thin-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.kanit-extralight-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.kanit-light-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.kanit-regular-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.kanit-medium-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.kanit-semibold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.kanit-bold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.kanit-extrabold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.kanit-black-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: italic;
}